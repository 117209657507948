<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="身份验证" />
    </van-sticky>
    <div style="text-align: center; margin-top: 25px; margin-bottom: 30px">
      <p style="font-size: 20px; color: red; margin: 15px">
        您的账号长期未使用，<br />
        为了您账号安全和公司信息安全，系统会向您的个人手机发送短信验证码，请验证后使用系统
      </p>
    </div>
    <van-cell-group>
      <van-field
        @blur="checksmsCode"
        label="验证码"
        maxlength="6"
        size="large"
        type="digit"
        v-model="para.smsCode"
        placeholder="请输手机验证码"
        :error-message="paraErr.smsCode"
      >
        <template #button>
          <van-button
            size="small"
            type="info"
            @click="sendSMSCode"
            :disabled="disabledSend"
            :text="sendText"
          ></van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div class="btn-box">
      <van-button
        type="info"
        size="large"
        round
        block
        @click="checkUserCode"
        :disabled="disabledSubmit"
        >确认</van-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { checkUserLoginSMSCode, checkUserLoginCode } from "@/api/user";

export default {
  name: "findPassWord",
  data() {
    return {
      disabledSubmit: false,
      sendText: "发送验证码",
      disabledSend: false,
      time: 120,
      para: {
        smsCode: "",
      },
      paraErr: {
        smsCode: "",
      },
    };
  },
  created() {},
  methods: {
    checksmsCode() {
      if (!this.para.smsCode) {
        this.paraErr.smsCode = "验证码不能为空";
        return false;
      }
      this.paraErr.smsCode = "";
      return true;
    },

    async checkUserCode() {
      this.disabledSubmit = true;
      if (this.checksmsCode()) {
        const data = await checkUserLoginCode(this.para.smsCode);
        if (data.data.success) {
          Toast("用户验证成功");
          this.$router.replace({
            path: "/",
          });
        } else {
          Toast(data.data.errorMessage);
          this.disabledSubmit = false;
        }
      }
    },
    async sendSMSCode() {
      this.disabledSend = true;
      const send = await checkUserLoginSMSCode();
      if (send.data.success) {
        Toast("短信已发送");
        this.time = 90;
        let timeInter = setInterval(() => {
          this.time--;
          this.sendText = "已发送" + this.time + "s";
          if (this.time === 0) {
            this.sendText = "发送验证码";
            this.disabledSend = false;
            clearInterval(timeInter);
          }
        }, 1000);
      } else {
        Toast(send.data.errorMessage);
        this.disabledSend = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.btn-box {
  margin: 20px;
}
</style>